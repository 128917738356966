import { default as aboutDse8dYfvqLMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as events9llYHYqXfyMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_939u0j0QqTr8Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplenE0EfwNANqMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsk9OadwQNzUMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93r2uz0NMgWpMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewrmWExQvfrTMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexc9gj7o7BTHMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93xV0cZPgp1sMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexc1Iq0yTQyqMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93UpfRYTwE88Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexM9znwAWWFoMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsgH2njqWAJlMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsrwv9XhjFmmMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewskPFiFGBAb6Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93PSrXJe0qOpMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexu3IxVeCSLRMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmBaejgFiX8iMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmJ9F5cvIcBPMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarvvLyyQdRZCMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/calendar.vue?macro=true";
import { default as chat04OTKIJcH5Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutZKME7xRfkbMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedibdnLHxZ9kMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersBZqgv9cBE6Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_931uKOhDdAgQMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editb2vrnjPu37Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93VrpcX4jXu5Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityDoDyhZndMbMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93adwpJkvZOhMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93kpCZy8xmQOMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardrGTbNlXxkIMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexbhvD7VV72ZMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutCy9po3wl6VMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productcEO0FedLLYMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as events5xMQmxOy3VMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93hyiCLWaVF0Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexBLnuuNljcjMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93gueVMhmUevMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93mDq0utIHukMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexxIr2ecPwB8Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93pIvIbQeB1nMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinCpm6xMZiiTMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/join.vue?macro=true";
import { default as loginhpZAl0R21WMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/login.vue?macro=true";
import { default as logoutRHefCq70duMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93pdg3iNEGjrMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93lGRnMOMMIUMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_9352m1ldVMg9Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesAKqxvqoIWrMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosFPBLGODHd1Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinybHpZJuLDiMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93S9ZXC7huPOMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexWH4U5WGe4pMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexJa3caai5TvMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as about5NR43DarhuMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as eventsQMEixlvL3iMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93FdbXAdKAoTMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sample8BtcoCfJIyMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsYjtrcfsMqjMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_9398F11btEFQMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewPZlvkMNbAUMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexfieFJIPSwTMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_931Y9HdtUQwBMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexToCUKEqB46Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93OrEMgicAhiMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexh6S2yA5kr1Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessions0oMc0YrJr4Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsS5JJQocbtrMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsQ8GdlTpRgJMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_9333v7XKPXqSMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexc76Fv6XV9MMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexar2R8DObDMMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesXOCT1BJQiLMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsqKcNO0qTOkMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93RDNRMst6mWMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93rqWd3065kwMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as reset7kw2226dWUMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/reset.vue?macro=true";
import { default as shopSDo7Zux6JNMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/shop.vue?macro=true";
import { default as signup1Ya5f2jnQfMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/signup.vue?macro=true";
import { default as live6at8ifvbJRMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsaFeNFJ99M2Meta } from "/tmp/build_0a7de69b/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93TKWyGC3uSyMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactqDuDjVFXrbMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullLeKgRfVEdWMeta } from "/tmp/build_0a7de69b/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93PSrXJe0qOpMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93PSrXJe0qOpMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93PSrXJe0qOpMeta || {},
    alias: _91product_id_93PSrXJe0qOpMeta?.alias || [],
    redirect: _91product_id_93PSrXJe0qOpMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutDse8dYfvqLMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutDse8dYfvqLMeta?.path ?? "about",
    meta: aboutDse8dYfvqLMeta || {},
    alias: aboutDse8dYfvqLMeta?.alias || [],
    redirect: aboutDse8dYfvqLMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: events9llYHYqXfyMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: events9llYHYqXfyMeta?.path ?? "events",
    meta: events9llYHYqXfyMeta || {},
    alias: events9llYHYqXfyMeta?.alias || [],
    redirect: events9llYHYqXfyMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_939u0j0QqTr8Meta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_939u0j0QqTr8Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_939u0j0QqTr8Meta || {},
    alias: _91media_id_939u0j0QqTr8Meta?.alias || [],
    redirect: _91media_id_939u0j0QqTr8Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplenE0EfwNANqMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45samplenE0EfwNANqMeta?.path ?? "free-sample",
    meta: free_45samplenE0EfwNANqMeta || {},
    alias: free_45samplenE0EfwNANqMeta?.alias || [],
    redirect: free_45samplenE0EfwNANqMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsk9OadwQNzUMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsk9OadwQNzUMeta?.path ?? "included-products",
    meta: included_45productsk9OadwQNzUMeta || {},
    alias: included_45productsk9OadwQNzUMeta?.alias || [],
    redirect: included_45productsk9OadwQNzUMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93r2uz0NMgWpMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93r2uz0NMgWpMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93r2uz0NMgWpMeta || {},
    alias: _91media_id_93r2uz0NMgWpMeta?.alias || [],
    redirect: _91media_id_93r2uz0NMgWpMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewrmWExQvfrTMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewrmWExQvfrTMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewrmWExQvfrTMeta || {},
    alias: viewrmWExQvfrTMeta?.alias || [],
    redirect: viewrmWExQvfrTMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexc9gj7o7BTHMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexc9gj7o7BTHMeta?.path ?? "lessons",
    meta: indexc9gj7o7BTHMeta || {},
    alias: indexc9gj7o7BTHMeta?.alias || [],
    redirect: indexc9gj7o7BTHMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93xV0cZPgp1sMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93xV0cZPgp1sMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93xV0cZPgp1sMeta || {},
    alias: _91media_id_93xV0cZPgp1sMeta?.alias || [],
    redirect: _91media_id_93xV0cZPgp1sMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexc1Iq0yTQyqMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexc1Iq0yTQyqMeta?.path ?? "playlists/:playlist_id()",
    meta: indexc1Iq0yTQyqMeta || {},
    alias: indexc1Iq0yTQyqMeta?.alias || [],
    redirect: indexc1Iq0yTQyqMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93UpfRYTwE88Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93UpfRYTwE88Meta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93UpfRYTwE88Meta || {},
    alias: _91media_id_93UpfRYTwE88Meta?.alias || [],
    redirect: _91media_id_93UpfRYTwE88Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexM9znwAWWFoMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexM9znwAWWFoMeta?.path ?? "playlists",
    meta: indexM9znwAWWFoMeta || {},
    alias: indexM9znwAWWFoMeta?.alias || [],
    redirect: indexM9znwAWWFoMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsgH2njqWAJlMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsgH2njqWAJlMeta?.path ?? "private-sessions",
    meta: private_45sessionsgH2njqWAJlMeta || {},
    alias: private_45sessionsgH2njqWAJlMeta?.alias || [],
    redirect: private_45sessionsgH2njqWAJlMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsrwv9XhjFmmMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsrwv9XhjFmmMeta?.path ?? "recordings",
    meta: recordingsrwv9XhjFmmMeta || {},
    alias: recordingsrwv9XhjFmmMeta?.alias || [],
    redirect: recordingsrwv9XhjFmmMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewskPFiFGBAb6Meta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewskPFiFGBAb6Meta?.path ?? "reviews",
    meta: reviewskPFiFGBAb6Meta || {},
    alias: reviewskPFiFGBAb6Meta?.alias || [],
    redirect: reviewskPFiFGBAb6Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexu3IxVeCSLRMeta?.name ?? "buy-product-product_id",
    path: indexu3IxVeCSLRMeta?.path ?? "/buy/product/:product_id()",
    meta: indexu3IxVeCSLRMeta || {},
    alias: indexu3IxVeCSLRMeta?.alias || [],
    redirect: indexu3IxVeCSLRMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmBaejgFiX8iMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmBaejgFiX8iMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmBaejgFiX8iMeta || {},
    alias: confirmBaejgFiX8iMeta?.alias || [],
    redirect: confirmBaejgFiX8iMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmJ9F5cvIcBPMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmJ9F5cvIcBPMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmJ9F5cvIcBPMeta || {},
    alias: confirmJ9F5cvIcBPMeta?.alias || [],
    redirect: confirmJ9F5cvIcBPMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarvvLyyQdRZCMeta?.name ?? "calendar",
    path: calendarvvLyyQdRZCMeta?.path ?? "/calendar",
    meta: calendarvvLyyQdRZCMeta || {},
    alias: calendarvvLyyQdRZCMeta?.alias || [],
    redirect: calendarvvLyyQdRZCMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chat04OTKIJcH5Meta?.name ?? "chat",
    path: chat04OTKIJcH5Meta?.path ?? "/chat",
    meta: chat04OTKIJcH5Meta || {},
    alias: chat04OTKIJcH5Meta?.alias || [],
    redirect: chat04OTKIJcH5Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_931uKOhDdAgQMeta?.name ?? "community-groups-group_id",
    path: _91group_id_931uKOhDdAgQMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_931uKOhDdAgQMeta || {},
    alias: _91group_id_931uKOhDdAgQMeta?.alias || [],
    redirect: _91group_id_931uKOhDdAgQMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutZKME7xRfkbMeta?.name ?? "community-groups-group_id-about",
    path: aboutZKME7xRfkbMeta?.path ?? "about",
    meta: aboutZKME7xRfkbMeta || {},
    alias: aboutZKME7xRfkbMeta?.alias || [],
    redirect: aboutZKME7xRfkbMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedibdnLHxZ9kMeta?.name ?? "community-groups-group_id-feed",
    path: feedibdnLHxZ9kMeta?.path ?? "feed",
    meta: feedibdnLHxZ9kMeta || {},
    alias: feedibdnLHxZ9kMeta?.alias || [],
    redirect: feedibdnLHxZ9kMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersBZqgv9cBE6Meta?.name ?? "community-groups-group_id-members",
    path: membersBZqgv9cBE6Meta?.path ?? "members",
    meta: membersBZqgv9cBE6Meta || {},
    alias: membersBZqgv9cBE6Meta?.alias || [],
    redirect: membersBZqgv9cBE6Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editb2vrnjPu37Meta?.name ?? "community-profile-edit",
    path: community_45profile_45editb2vrnjPu37Meta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editb2vrnjPu37Meta || {},
    alias: community_45profile_45editb2vrnjPu37Meta?.alias || [],
    redirect: community_45profile_45editb2vrnjPu37Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VrpcX4jXu5Meta?.name ?? "community-profile-id",
    path: _91id_93VrpcX4jXu5Meta?.path ?? "/community-profile/:id()",
    meta: _91id_93VrpcX4jXu5Meta || {},
    alias: _91id_93VrpcX4jXu5Meta?.alias || [],
    redirect: _91id_93VrpcX4jXu5Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityDoDyhZndMbMeta?.name ?? "community",
    path: communityDoDyhZndMbMeta?.path ?? "/community",
    meta: communityDoDyhZndMbMeta || {},
    alias: communityDoDyhZndMbMeta?.alias || [],
    redirect: communityDoDyhZndMbMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93adwpJkvZOhMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93adwpJkvZOhMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93adwpJkvZOhMeta || {},
    alias: _91uuid_93adwpJkvZOhMeta?.alias || [],
    redirect: _91uuid_93adwpJkvZOhMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93kpCZy8xmQOMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93kpCZy8xmQOMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93kpCZy8xmQOMeta || {},
    alias: _91unique_id_93kpCZy8xmQOMeta?.alias || [],
    redirect: _91unique_id_93kpCZy8xmQOMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardrGTbNlXxkIMeta?.name ?? "dashboard",
    path: dashboardrGTbNlXxkIMeta?.path ?? "/dashboard",
    meta: dashboardrGTbNlXxkIMeta || {},
    alias: dashboardrGTbNlXxkIMeta?.alias || [],
    redirect: dashboardrGTbNlXxkIMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexbhvD7VV72ZMeta?.name ?? "event-details-event_id",
    path: indexbhvD7VV72ZMeta?.path ?? "/event/details/:event_id()",
    meta: indexbhvD7VV72ZMeta || {},
    alias: indexbhvD7VV72ZMeta?.alias || [],
    redirect: indexbhvD7VV72ZMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutCy9po3wl6VMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutCy9po3wl6VMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutCy9po3wl6VMeta || {},
    alias: checkoutCy9po3wl6VMeta?.alias || [],
    redirect: checkoutCy9po3wl6VMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productcEO0FedLLYMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productcEO0FedLLYMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productcEO0FedLLYMeta || {},
    alias: select_45productcEO0FedLLYMeta?.alias || [],
    redirect: select_45productcEO0FedLLYMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: events5xMQmxOy3VMeta?.name ?? "events",
    path: events5xMQmxOy3VMeta?.path ?? "/events",
    meta: events5xMQmxOy3VMeta || {},
    alias: events5xMQmxOy3VMeta?.alias || [],
    redirect: events5xMQmxOy3VMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93hyiCLWaVF0Meta?.name ?? "gifts-uuid",
    path: _91uuid_93hyiCLWaVF0Meta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93hyiCLWaVF0Meta || {},
    alias: _91uuid_93hyiCLWaVF0Meta?.alias || [],
    redirect: _91uuid_93hyiCLWaVF0Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexBLnuuNljcjMeta?.name ?? "gifts",
    path: indexBLnuuNljcjMeta?.path ?? "/gifts",
    meta: indexBLnuuNljcjMeta || {},
    alias: indexBLnuuNljcjMeta?.alias || [],
    redirect: indexBLnuuNljcjMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93gueVMhmUevMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93gueVMhmUevMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93gueVMhmUevMeta || {},
    alias: _91invitation_id_93gueVMhmUevMeta?.alias || [],
    redirect: _91invitation_id_93gueVMhmUevMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93mDq0utIHukMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93mDq0utIHukMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93mDq0utIHukMeta || {},
    alias: _91product_id_93mDq0utIHukMeta?.alias || [],
    redirect: _91product_id_93mDq0utIHukMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexxIr2ecPwB8Meta?.name ?? "index",
    path: indexxIr2ecPwB8Meta?.path ?? "/",
    meta: indexxIr2ecPwB8Meta || {},
    alias: indexxIr2ecPwB8Meta?.alias || [],
    redirect: indexxIr2ecPwB8Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93pIvIbQeB1nMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93pIvIbQeB1nMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93pIvIbQeB1nMeta || {},
    alias: _91invitation_unique_id_93pIvIbQeB1nMeta?.alias || [],
    redirect: _91invitation_unique_id_93pIvIbQeB1nMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinCpm6xMZiiTMeta?.name ?? "join",
    path: joinCpm6xMZiiTMeta?.path ?? "/join",
    meta: joinCpm6xMZiiTMeta || {},
    alias: joinCpm6xMZiiTMeta?.alias || [],
    redirect: joinCpm6xMZiiTMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginhpZAl0R21WMeta?.name ?? "login",
    path: loginhpZAl0R21WMeta?.path ?? "/login",
    meta: loginhpZAl0R21WMeta || {},
    alias: loginhpZAl0R21WMeta?.alias || [],
    redirect: loginhpZAl0R21WMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutRHefCq70duMeta?.name ?? "logout",
    path: logoutRHefCq70duMeta?.path ?? "/logout",
    meta: logoutRHefCq70duMeta || {},
    alias: logoutRHefCq70duMeta?.alias || [],
    redirect: logoutRHefCq70duMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93pdg3iNEGjrMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93pdg3iNEGjrMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93pdg3iNEGjrMeta || {},
    alias: _91meeting_type_slug_93pdg3iNEGjrMeta?.alias || [],
    redirect: _91meeting_type_slug_93pdg3iNEGjrMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93lGRnMOMMIUMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93lGRnMOMMIUMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93lGRnMOMMIUMeta || {},
    alias: _91meeting_unique_id_93lGRnMOMMIUMeta?.alias || [],
    redirect: _91meeting_unique_id_93lGRnMOMMIUMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_9352m1ldVMg9Meta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_9352m1ldVMg9Meta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_9352m1ldVMg9Meta || {},
    alias: _91meeting_unique_id_9352m1ldVMg9Meta?.alias || [],
    redirect: _91meeting_unique_id_9352m1ldVMg9Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesAKqxvqoIWrMeta?.name ?? "my-favorites",
    path: my_45favoritesAKqxvqoIWrMeta?.path ?? "/my-favorites",
    meta: my_45favoritesAKqxvqoIWrMeta || {},
    alias: my_45favoritesAKqxvqoIWrMeta?.alias || [],
    redirect: my_45favoritesAKqxvqoIWrMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosFPBLGODHd1Meta?.name ?? "my-studios",
    path: my_45studiosFPBLGODHd1Meta?.path ?? "/my-studios",
    meta: my_45studiosFPBLGODHd1Meta || {},
    alias: my_45studiosFPBLGODHd1Meta?.alias || [],
    redirect: my_45studiosFPBLGODHd1Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinybHpZJuLDiMeta?.name ?? "optin",
    path: optinybHpZJuLDiMeta?.path ?? "/optin",
    meta: optinybHpZJuLDiMeta || {},
    alias: optinybHpZJuLDiMeta?.alias || [],
    redirect: optinybHpZJuLDiMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93S9ZXC7huPOMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93S9ZXC7huPOMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93S9ZXC7huPOMeta || {},
    alias: _91episode_id_93S9ZXC7huPOMeta?.alias || [],
    redirect: _91episode_id_93S9ZXC7huPOMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexWH4U5WGe4pMeta?.name ?? "podcasts-podcast_id",
    path: indexWH4U5WGe4pMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexWH4U5WGe4pMeta || {},
    alias: indexWH4U5WGe4pMeta?.alias || [],
    redirect: indexWH4U5WGe4pMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJa3caai5TvMeta?.name ?? "podcasts",
    path: indexJa3caai5TvMeta?.path ?? "/podcasts",
    meta: indexJa3caai5TvMeta || {},
    alias: indexJa3caai5TvMeta?.alias || [],
    redirect: indexJa3caai5TvMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_9333v7XKPXqSMeta?.name ?? "product-product_id",
    path: _91product_id_9333v7XKPXqSMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_9333v7XKPXqSMeta || {},
    alias: _91product_id_9333v7XKPXqSMeta?.alias || [],
    redirect: _91product_id_9333v7XKPXqSMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: about5NR43DarhuMeta?.name ?? "product-product_id-about",
    path: about5NR43DarhuMeta?.path ?? "about",
    meta: about5NR43DarhuMeta || {},
    alias: about5NR43DarhuMeta?.alias || [],
    redirect: about5NR43DarhuMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsQMEixlvL3iMeta?.name ?? "product-product_id-events",
    path: eventsQMEixlvL3iMeta?.path ?? "events",
    meta: eventsQMEixlvL3iMeta || {},
    alias: eventsQMEixlvL3iMeta?.alias || [],
    redirect: eventsQMEixlvL3iMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93FdbXAdKAoTMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93FdbXAdKAoTMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93FdbXAdKAoTMeta || {},
    alias: _91media_id_93FdbXAdKAoTMeta?.alias || [],
    redirect: _91media_id_93FdbXAdKAoTMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sample8BtcoCfJIyMeta?.name ?? "product-product_id-free-sample",
    path: free_45sample8BtcoCfJIyMeta?.path ?? "free-sample",
    meta: free_45sample8BtcoCfJIyMeta || {},
    alias: free_45sample8BtcoCfJIyMeta?.alias || [],
    redirect: free_45sample8BtcoCfJIyMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsYjtrcfsMqjMeta?.name ?? "product-product_id-included-products",
    path: included_45productsYjtrcfsMqjMeta?.path ?? "included-products",
    meta: included_45productsYjtrcfsMqjMeta || {},
    alias: included_45productsYjtrcfsMqjMeta?.alias || [],
    redirect: included_45productsYjtrcfsMqjMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_9398F11btEFQMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_9398F11btEFQMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_9398F11btEFQMeta || {},
    alias: _91media_id_9398F11btEFQMeta?.alias || [],
    redirect: _91media_id_9398F11btEFQMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewPZlvkMNbAUMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewPZlvkMNbAUMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewPZlvkMNbAUMeta || {},
    alias: viewPZlvkMNbAUMeta?.alias || [],
    redirect: viewPZlvkMNbAUMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexfieFJIPSwTMeta?.name ?? "product-product_id-lessons",
    path: indexfieFJIPSwTMeta?.path ?? "lessons",
    meta: indexfieFJIPSwTMeta || {},
    alias: indexfieFJIPSwTMeta?.alias || [],
    redirect: indexfieFJIPSwTMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_931Y9HdtUQwBMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_931Y9HdtUQwBMeta?.path ?? "media/:media_id()",
    meta: _91media_id_931Y9HdtUQwBMeta || {},
    alias: _91media_id_931Y9HdtUQwBMeta?.alias || [],
    redirect: _91media_id_931Y9HdtUQwBMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexToCUKEqB46Meta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexToCUKEqB46Meta?.path ?? "playlists/:playlist_id()",
    meta: indexToCUKEqB46Meta || {},
    alias: indexToCUKEqB46Meta?.alias || [],
    redirect: indexToCUKEqB46Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93OrEMgicAhiMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93OrEMgicAhiMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93OrEMgicAhiMeta || {},
    alias: _91media_id_93OrEMgicAhiMeta?.alias || [],
    redirect: _91media_id_93OrEMgicAhiMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexh6S2yA5kr1Meta?.name ?? "product-product_id-playlists",
    path: indexh6S2yA5kr1Meta?.path ?? "playlists",
    meta: indexh6S2yA5kr1Meta || {},
    alias: indexh6S2yA5kr1Meta?.alias || [],
    redirect: indexh6S2yA5kr1Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessions0oMc0YrJr4Meta?.name ?? "product-product_id-private-sessions",
    path: private_45sessions0oMc0YrJr4Meta?.path ?? "private-sessions",
    meta: private_45sessions0oMc0YrJr4Meta || {},
    alias: private_45sessions0oMc0YrJr4Meta?.alias || [],
    redirect: private_45sessions0oMc0YrJr4Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsS5JJQocbtrMeta?.name ?? "product-product_id-recordings",
    path: recordingsS5JJQocbtrMeta?.path ?? "recordings",
    meta: recordingsS5JJQocbtrMeta || {},
    alias: recordingsS5JJQocbtrMeta?.alias || [],
    redirect: recordingsS5JJQocbtrMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsQ8GdlTpRgJMeta?.name ?? "product-product_id-reviews",
    path: reviewsQ8GdlTpRgJMeta?.path ?? "reviews",
    meta: reviewsQ8GdlTpRgJMeta || {},
    alias: reviewsQ8GdlTpRgJMeta?.alias || [],
    redirect: reviewsQ8GdlTpRgJMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexc76Fv6XV9MMeta?.name ?? "product",
    path: indexc76Fv6XV9MMeta?.path ?? "/product",
    meta: indexc76Fv6XV9MMeta || {},
    alias: indexc76Fv6XV9MMeta?.alias || [],
    redirect: indexc76Fv6XV9MMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexar2R8DObDMMeta?.name ?? "profile",
    path: indexar2R8DObDMMeta?.path ?? "/profile",
    meta: indexar2R8DObDMMeta || {},
    alias: indexar2R8DObDMMeta?.alias || [],
    redirect: indexar2R8DObDMMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesXOCT1BJQiLMeta?.name ?? "profile-purchases",
    path: purchasesXOCT1BJQiLMeta?.path ?? "/profile/purchases",
    meta: purchasesXOCT1BJQiLMeta || {},
    alias: purchasesXOCT1BJQiLMeta?.alias || [],
    redirect: purchasesXOCT1BJQiLMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsqKcNO0qTOkMeta?.name ?? "profile-redemptions",
    path: redemptionsqKcNO0qTOkMeta?.path ?? "/profile/redemptions",
    meta: redemptionsqKcNO0qTOkMeta || {},
    alias: redemptionsqKcNO0qTOkMeta?.alias || [],
    redirect: redemptionsqKcNO0qTOkMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93RDNRMst6mWMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93RDNRMst6mWMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93RDNRMst6mWMeta || {},
    alias: _91quiz_id_93RDNRMst6mWMeta?.alias || [],
    redirect: _91quiz_id_93RDNRMst6mWMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93rqWd3065kwMeta?.name ?? "reset-password-uid-token",
    path: _91token_93rqWd3065kwMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93rqWd3065kwMeta || {},
    alias: _91token_93rqWd3065kwMeta?.alias || [],
    redirect: _91token_93rqWd3065kwMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: reset7kw2226dWUMeta?.name ?? "reset",
    path: reset7kw2226dWUMeta?.path ?? "/reset",
    meta: reset7kw2226dWUMeta || {},
    alias: reset7kw2226dWUMeta?.alias || [],
    redirect: reset7kw2226dWUMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopSDo7Zux6JNMeta?.name ?? "shop",
    path: shopSDo7Zux6JNMeta?.path ?? "/shop",
    meta: shopSDo7Zux6JNMeta || {},
    alias: shopSDo7Zux6JNMeta?.alias || [],
    redirect: shopSDo7Zux6JNMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signup1Ya5f2jnQfMeta?.name ?? "signup",
    path: signup1Ya5f2jnQfMeta?.path ?? "/signup",
    meta: signup1Ya5f2jnQfMeta || {},
    alias: signup1Ya5f2jnQfMeta?.alias || [],
    redirect: signup1Ya5f2jnQfMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: live6at8ifvbJRMeta?.name ?? "streaming-event_id-live",
    path: live6at8ifvbJRMeta?.path ?? "/streaming/:event_id()/live",
    meta: live6at8ifvbJRMeta || {},
    alias: live6at8ifvbJRMeta?.alias || [],
    redirect: live6at8ifvbJRMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsaFeNFJ99M2Meta?.name ?? "tips",
    path: tipsaFeNFJ99M2Meta?.path ?? "/tips",
    meta: tipsaFeNFJ99M2Meta || {},
    alias: tipsaFeNFJ99M2Meta?.alias || [],
    redirect: tipsaFeNFJ99M2Meta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93TKWyGC3uSyMeta?.name ?? "waiver-uuid",
    path: _91uuid_93TKWyGC3uSyMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93TKWyGC3uSyMeta || {},
    alias: _91uuid_93TKWyGC3uSyMeta?.alias || [],
    redirect: _91uuid_93TKWyGC3uSyMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactqDuDjVFXrbMeta?.name ?? "widgets-calendars-compact",
    path: compactqDuDjVFXrbMeta?.path ?? "/widgets/calendars/compact",
    meta: compactqDuDjVFXrbMeta || {},
    alias: compactqDuDjVFXrbMeta?.alias || [],
    redirect: compactqDuDjVFXrbMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullLeKgRfVEdWMeta?.name ?? "widgets-calendars-full",
    path: fullLeKgRfVEdWMeta?.path ?? "/widgets/calendars/full",
    meta: fullLeKgRfVEdWMeta || {},
    alias: fullLeKgRfVEdWMeta?.alias || [],
    redirect: fullLeKgRfVEdWMeta?.redirect,
    component: () => import("/tmp/build_0a7de69b/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]